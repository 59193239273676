import {
  UserIHaveAcceptedInviteFrom,
  UserIHaveSentInviteTo,
} from "@todesktop/shared";
import {
  Button,
  Checkbox,
  Divider,
  List,
  message,
  Modal,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
} from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { useStore } from "../../store";
import {
  goToManageSecurityToken,
  updateUIState,
} from "../../~reusables/actions";
import {
  callFirebaseFunction,
  deleteAcceptedUser,
  deleteInvitedUser,
  getInvitedUserData,
  updateInvitedUser,
  upsertAcceptedUser,
} from "../../~reusables/firebase";
import { Flex } from "../atoms/Primitives";
import { InviteUserForm } from "./InviteUserForm";
import { UsersList } from "./UsersList";

export const ManageUsers: React.FC = () => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const [modalVisible, setModalVisibility] = useState(true);
  const { user, acceptedUsers, invitedUsers } = useStore((state) => ({
    user: state.user,
    acceptedUsers: state.acceptedUsers,
    invitedUsers: state.invitedUsers,
  }));

  const closeModal = () => setModalVisibility(false);

  const { id } = query || {};
  useEffect(() => {
    async function updateUsersIHaveAcceptedInvitesFrom() {
      if (id && typeof id === "string" && user.email) {
        // remove the url search params (?id=xyz) related to the invite
        history.replace(window.location.pathname);

        try {
          const invitedUser = await getInvitedUserData(id, user.email);

          if (invitedUser) {
            await upsertAcceptedUser(id, {
              ...invitedUser.sender,
              receiver: { id: user.id, email: user.email },
            });
            message.success(
              `You now have delegated access to ${invitedUser.sender.firstName}'s apps`
            );
          }
        } catch (err) {
          message.error("Invite is invalid");
        }
      }
    }

    updateUsersIHaveAcceptedInvitesFrom();
  }, [id, user.email]);

  const [state, setState] = useState<{
    inviteStates: Record<string, Status>;
    acceptStates: Record<string, Status>;
  }>({ inviteStates: {}, acceptStates: {} });

  const [getUserInvitesStatus] = useDebouncedCallback(
    async (input: {
      invitedUsers: UserIHaveSentInviteTo[];
      acceptedUsers: UserIHaveAcceptedInviteFrom[];
    }) => {
      try {
        const callable = callFirebaseFunction("getUserInvitesStatus");
        const { data } = await callable(input);
        setState(data);
      } catch (err) {
        setState((previous) => {
          const inviteStates = {};
          for (const user of input.invitedUsers) {
            inviteStates[user.email] = "pending";
          }

          const acceptStates = {};
          for (const user of input.acceptedUsers) {
            acceptStates[user.id] = "pending";
          }

          return {
            ...previous,
            // keep latest fetched data by spreading the previous data last
            acceptStates: { ...acceptStates, ...previous.acceptStates },
            inviteStates: { ...inviteStates, ...previous.inviteStates },
          };
        });

        console.error(err);
      }
    },
    1000
  );

  useEffect(() => {
    if (!invitedUsers.length && !acceptedUsers.length) return;
    getUserInvitesStatus({ invitedUsers, acceptedUsers });
  }, [acceptedUsers, invitedUsers]);

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={() => updateUIState(null)}
    >
      <Space direction="vertical" size="small">
        <UsersList
          title="Manage invited users"
          description={
            <>
              A <a onClick={() => goToManageSecurityToken()}>security token</a>{" "}
              is required to invite new users to your account. Invited users
              won't be able to create/delete apps under your account or manage
              billing information.
            </>
          }
        >
          <List
            dataSource={invitedUsers}
            header={
              <Flex css={{ flexDirection: "column", paddingBottom: "8px" }}>
                <InviteUserForm />
              </Flex>
            }
            renderItem={(item) => {
              const status = state.inviteStates[item.email];
              return (
                <List.Item key={item.email}>
                  <List.Item.Meta
                    title={
                      <ListTitle
                        status={status}
                        statusTooltips={{
                          success: "User has accepted invite",
                          invalid: "User cannot accept invite",
                          pending: "User has not accepted invite",
                        }}
                      >
                        {item.name}
                      </ListTitle>
                    }
                    description={item.email}
                  />
                  <Flex
                    css={{
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <Popconfirm
                      title="Are you sure you want to remove this user?"
                      okText="Yes"
                      onConfirm={() => deleteInvitedUser(item.email)}
                    >
                      <Button size="small" type="ghost" danger>
                        Remove user
                      </Button>
                    </Popconfirm>
                    <Flex css={{ paddingBottom: "8px" }}>
                      <Checkbox
                        checked={item.permissions?.canBuild}
                        onChange={async (event) => {
                          await updateInvitedUser(item.email, {
                            permissions: { canBuild: event.target.checked },
                          });
                        }}
                      >
                        Can build
                      </Checkbox>
                      <Checkbox
                        checked={item.permissions?.canRelease}
                        onChange={async (event) => {
                          await updateInvitedUser(item.email, {
                            permissions: { canRelease: event.target.checked },
                          });
                        }}
                      >
                        Can release
                      </Checkbox>
                    </Flex>
                  </Flex>
                </List.Item>
              );
            }}
          />
        </UsersList>
        <Divider />
        <UsersList
          title="Manage delegated accounts"
          description="Manage the accounts from who you have accepted invites. "
        >
          <List
            dataSource={acceptedUsers}
            renderItem={(item) => {
              const status = state.acceptStates[item.id];

              return (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    title={
                      <ListTitle
                        status={status}
                        statusTooltips={{
                          success: "You have delegated access",
                          invalid: "You don't have delegated access",
                          pending: "Status pending",
                        }}
                      >
                        {item.firstName}
                      </ListTitle>
                    }
                    description={item.email}
                  />
                  <Popconfirm
                    title="Are you sure you wish to leave this account?"
                    okText="Yes"
                    onConfirm={() => deleteAcceptedUser(item.id)}
                  >
                    <Button size="small" type="ghost" danger>
                      Leave account
                    </Button>
                  </Popconfirm>
                </List.Item>
              );
            }}
          />
        </UsersList>
      </Space>
    </Modal>
  );
};

const ListTitle: React.FC<{
  status: Status;
  statusTooltips: Record<Status, string>;
}> = ({ children, status, statusTooltips }) => {
  return (
    <Flex css={{ alignItems: "center", gap: "8px" }}>
      {children}
      {!status || status === "success" ? null : (
        <Tooltip title={statusTooltips[status]}>
          <Tag color={statusColors[status]}>{status}</Tag>
        </Tooltip>
      )}
    </Flex>
  );
};

type Status = "pending" | "success" | "invalid";
const statusColors: Record<Status, string> = {
  success: "success",
  invalid: "error",
  pending: "default",
};
