import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import firebase from "firebase/app";
import React, { useState } from "react";
import { v4 } from "uuid";
import { selectedApp, useStore } from "../../store";
import { updateFirestoreApp } from "../../~reusables/actions";
import { callFirebaseFunction } from "../../~reusables/firebase";
import { Heading } from "../atoms/Heading";
import { Box, Flex } from "../atoms/Primitives";
import { Space } from "../atoms/Space";
import { StyledAntdCard } from "../atoms/StyledAntdCard";
import { RevealableText } from "../atoms/TextUtils";

const { Text } = Typography;

export const EnvironmentConfigCard: React.FC = () => {
  const { environmentVariables } = useStore(selectedApp);
  const sortedEnvironmentVariables =
    environmentVariables && typeof environmentVariables === "object"
      ? (Object.entries(environmentVariables).sort() as [string, string][])
      : [];

  return (
    <StyledAntdCard
      title={
        <Space direction="column" align="flex-start">
          <Flex alignItems="center">
            <Heading variant="h4" as="h4">
              Environment Variables
            </Heading>
          </Flex>
          <Text type="secondary" style={{ fontWeight: 400 }}>
            Set environment variables to be used in your builds
          </Text>
        </Space>
      }
    >
      <EnvironmentVariableForm />
      <Box borderTop="1px solid" borderTopColor="greys.6" padding="16px 8px">
        <Row gutter={16}>
          <Col sm={8}>
            <Text strong>Key</Text>
          </Col>
          <Col sm={8}>
            <Text strong>Value</Text>
          </Col>
        </Row>
      </Box>
      {sortedEnvironmentVariables.map(([key, value], idx) => (
        <EnvironmentVariable
          key={key}
          envKey={key}
          envValue={value}
          shouldAlternateBg={idx % 2 !== 0}
        />
      ))}
    </StyledAntdCard>
  );
};

const EnvironmentVariable: React.FC<{
  envKey: string;
  envValue: string | { secret: string };
  shouldAlternateBg: boolean;
}> = ({ envKey, envValue, shouldAlternateBg }) => {
  const { id: appId } = useStore(selectedApp);

  const [state, setState] = useState({
    editing: false,
    loading: false,
    deleting: false,
  });
  const isSecret = typeof envValue !== "string";

  return (
    <Box
      backgroundColor={shouldAlternateBg ? "greys.8" : "white"}
      borderRadius={1}
      padding="12px 8px"
    >
      {state.editing ? (
        <Form
          size="small"
          initialValues={{ key: envKey, value: envValue, secret: isSecret }}
          onFinish={async ({ key, value, secret }) => {
            if (isSecret) {
              return message.error(
                "Encrypted environment variables cannot be updated"
              );
            }

            try {
              setState((prev) => ({ ...prev, loading: true }));
              await setEnvironmentVariable(appId, key, value, secret);
              setState((prev) => ({ ...prev, editing: false, loading: false }));
            } catch (err) {
              message.error(err.message);
              setState((prev) => ({ ...prev, loading: false }));
            }
          }}
        >
          <Box
            backgroundColor={shouldAlternateBg ? "greys.8" : "white"}
            borderRadius={1}
          >
            <Row gutter={16}>
              <Col sm={8}>
                <Form.Item name="key">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item name="value" rules={[{ required: true }]}>
                  <Input.Password disabled={isSecret} />
                </Form.Item>
              </Col>
              <Col
                sm={8}
                style={{ display: "flex", flexWrap: "wrap", gap: "12px 8px" }}
              >
                <Form.Item name="secret" valuePropName="checked" noStyle>
                  <Checkbox disabled={isSecret}>Encrypt</Checkbox>
                </Form.Item>
                <Button
                  style={{ flex: 1 }}
                  size="small"
                  htmlType="button"
                  onClick={() => {
                    setState((prev) => ({ ...prev, editing: false }));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={state.loading}
                  style={{ flex: 1 }}
                  size="small"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Box>
        </Form>
      ) : (
        <Row gutter={24}>
          <Col sm={8}>
            <Text>{envKey}</Text>
          </Col>
          <Col sm={8}>
            {}
            {typeof envValue === "string" ? (
              <Text>
                <RevealableText revealText={envValue} />
              </Text>
            ) : (
              <Text>*********</Text>
            )}
          </Col>
          <Col sm={8} style={{ display: "flex", gap: "8px" }}>
            <Button
              style={{ flex: 1 }}
              size="small"
              htmlType="button"
              onClick={() => {
                setState((prev) => ({ ...prev, editing: true }));
              }}
            >
              Edit
            </Button>
            <Button
              style={{ flex: 1 }}
              size="small"
              danger
              loading={state.deleting}
              onClick={() => {
                Modal.confirm({
                  content:
                    "Are you sure you want to delete this environment variable?",
                  async onOk() {
                    try {
                      setState((prev) => ({ ...prev, deleting: true }));
                      await deleteEnvironmentVariable(appId, envKey, envValue);
                    } finally {
                      setState((prev) => ({ ...prev, deleting: false }));
                    }
                  },
                  okText: "Yes, Delete",
                  okType: "danger",
                  cancelText: "No",
                });
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      )}
    </Box>
  );
};

const EnvironmentVariableForm: React.FC = () => {
  const { id: appId, environmentVariables = {} } = useStore(selectedApp);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ key: "", value: "", secret: false }}
      onFinish={async ({ key, value, secret }) => {
        if (environmentVariables[key]) {
          return message.error("This environment variable key already exists");
        }

        try {
          setLoading(true);
          await setEnvironmentVariable(appId, key, value, secret);
          form.resetFields();
        } catch (err) {
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      }}
    >
      <Flex css={{ width: "100%", gap: "16px" }}>
        <Form.Item
          label="Key"
          name="key"
          rules={[
            {
              required: true,
              pattern: /^[0-9a-zA-Z-]+$/,
              message: `The key must contain only 0-9, a-z, A-Z, and -.`,
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true }]}
          style={{ flex: 1 }}
        >
          <Input />
        </Form.Item>
      </Flex>
      <Form.Item name="secret" valuePropName="checked" noStyle>
        <Flex css={{ paddingBottom: "16px" }}>
          <Checkbox>
            Encrypt this value (you will not be able to view this value after
            encryption)
          </Checkbox>
        </Flex>
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Add variable
        </Button>
      </Form.Item>
    </Form>
  );
};

const setEnvironmentVariable = async (
  appId: string,
  key: string,
  value: unknown,
  secret = false
) => {
  const envValue = secret
    ? {
        secret: (await addAppSecret({ appId, key: `${key}-${v4()}`, value }))
          .data.name,
      }
    : value;

  updateFirestoreApp({ environmentVariables: { [key]: envValue } });
};

const deleteEnvironmentVariable = async (
  appId: string,
  key: string,
  value: string | { secret: string }
) => {
  if (typeof value !== "string") {
    await deleteAppSecret({ appId, key: value.secret });
  }

  updateFirestoreApp({
    environmentVariables: {
      [key]: firebase.firestore.FieldValue.delete() as any,
    },
  });
};

const addAppSecret = callFirebaseFunction("addAppSecret");
const deleteAppSecret = callFirebaseFunction("deleteAppSecret");
