import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { getPlan, IApp, Plan, Product, products } from "@todesktop/shared";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Switch,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { selectedApp, useStore } from "../../store";
import {
  createCustomerCheckoutSession,
  removeUIState,
  updateAndDispatchApp,
  updateUIState,
} from "../../~reusables/actions";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { stage } from "../../~reusables/firebase/config";
import { history } from "../../~reusables/util";
import { Flex } from "../atoms/Primitives";

const { Title, Text } = Typography;

interface SubscribeModalState {
  tab: "subscribe" | "connect";
}

export const SubscribeModal: React.FC = () => {
  const app = useStore(selectedApp);
  const data = useReusableAppPlans(app.id);
  const [state, setState] = useState<SubscribeModalState>({ tab: "subscribe" });
  const { uiState, user } = useStore((state) => ({
    uiState: state.uiState,
    user: state.user,
  }));

  useEffect(() => {
    if (user) return;
    updateUIState("convert-login");
  }, [user]);

  return (
    <Modal
      open={uiState === "subscribe"}
      width={676}
      footer={null}
      closable={true}
      centered={true}
      onCancel={() => {
        if (app.appType === "electron") {
          Modal.confirm({
            title: "Closing this modal will redirect you back home",
            onOk() {
              history.push("/");
              removeUIState();
            },
          });
        } else {
          removeUIState();
        }
      }}
    >
      <Tabs
        type="card"
        activeKey={state.tab}
        centered
        onChange={(tab: SubscribeModalState["tab"]) => {
          setState((state) => ({ ...state, tab }));
        }}
        items={[
          {
            key: "subscribe",
            label: "Start free 7-day trial",
            children: (
              <CLIPlans
                onSelectPrice={async (priceId) => {
                  await createCustomerCheckoutSession({
                    priceId,
                    appId: app.id,
                  });
                }}
              />
            ),
          },
          ...(data.length
            ? [
                {
                  key: "connect",
                  label: "Reuse existing plan",
                  children: (
                    <ConnectToExistingAppPlan
                      subscriptionAppId={app.id}
                      data={data}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Modal>
  );
};

const ConnectToExistingAppPlan: React.FC<{
  subscriptionAppId: string;
  data: { app: IApp; plan: Plan }[];
}> = ({ subscriptionAppId, data }) => {
  const { colors } = useTheme();

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        padding: "16px 0",
      }}
    >
      <Text>Select the payment plan to attach your app to</Text>
      <Card
        size="small"
        style={{
          background: colors.lightBackground,
          width: "100%",
          height: "100%",
        }}
      >
        <Form
          initialValues={{ appId: data[0].app.id, relationship: "" }}
          layout="vertical"
          onFinish={async (data) => {
            await updateAndDispatchApp(subscriptionAppId, {
              parent: {
                id: data.appId,
                relationshipToParent: data.relationship,
              },
            });
          }}
        >
          <Form.Item
            label="Existing plan"
            name="appId"
            rules={[{ required: true, message: "Application is required" }]}
          >
            <Select
              options={data.map(({ app, plan }) => {
                return {
                  value: app.id,
                  label: (
                    <Flex
                      css={{
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "16px",
                      }}
                    >
                      {plan.label} plan ({app.name})
                      <img src={app.icon} width={20} height={20} />
                    </Flex>
                  ),
                };
              })}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const { appId: selectedId } = getFieldsValue();
              const selectedData = data.find(
                ({ app }) => app.id === selectedId
              );

              return (
                <Form.Item
                  label={
                    selectedData
                      ? `Relationship to ${selectedData.app.name}`
                      : "Relationship"
                  }
                  name="relationship"
                  rules={[
                    { required: true, message: "Relationship is required." },
                  ]}
                >
                  <Input placeholder="Staging" />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Connect plan
          </Button>
        </Form>
      </Card>
      <Text type="secondary" style={{ textAlign: "center" }}>
        Pro plans can have up to 2 other apps, while Scale plans can have up to
        10.
      </Text>
    </Flex>
  );
};

const useReusableAppPlans = (appId: string) => {
  const apps = useStore((state) => state.apps);
  return useMemo(() => {
    const eligibleApps: { app: IApp; plan: Plan }[] = [];
    const record: Record<string, IApp[]> = {};

    for (const app of apps) {
      if (!app.parent) continue;
      if (record[app.parent.id]) {
        record[app.parent.id].push(app);
      } else {
        record[app.parent.id] = [app];
      }
    }

    for (const app of apps) {
      if (!app.subscription) continue;
      if (app.id === appId) continue;
      if (app.appType !== "electron") continue;

      const plan = getPlan(app.subscription);

      switch (plan.tier) {
        case "basic":
          // basic plans can't accept new children
          break;
        case "legacy_pro":
        case "pro":
          const apps = record[app.id] || [];
          if (apps.length < 2) eligibleApps.push({ app, plan });
          break;
        case "scale":
          eligibleApps.push({ app, plan });
          break;
      }
    }

    return eligibleApps;
  }, [appId, apps]);
};

export const CLIPlans: React.FC<{
  filteredProductIds?: string[];
  featureLimit?: number;
  onSelectPrice: (priceId: string) => Promise<void>;
  onViewOtherPlans?: () => void;
}> = ({
  children,
  filteredProductIds,
  featureLimit,
  onSelectPrice,
  onViewOtherPlans,
}) => {
  return (
    <Plans
      filteredProductIds={filteredProductIds}
      featureLimit={featureLimit}
      defaultProductId={products[stage].cli.performance.id}
      products={[
        {
          label: "Essential",
          product: products[stage].cli.founder,
          features: [
            {
              label: (
                <>
                  <strong>35</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>30 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>5000</strong> downloads/month
                </>
              ),
            },
            { label: "Windows, Mac & Linux build servers" },
            { label: "Code signing" },
            { label: "Auto updates" },
            { label: "Native app installers" },
            { label: "Native module rebuilding" },
            { label: "Automated Electron code analysis" },
            { label: "CDN-backed downloads/updates" },
            { label: "Download links on your domain" },
          ],
        },
        {
          label: "Performance",
          product: products[stage].cli.performance,
          features: [
            {
              label: (
                <>
                  <strong>120</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>15,000</strong> downloads/month
                </>
              ),
            },
            { label: "Staging and dev apps" },
            { label: "Auto-update smoke testing" },
            { label: "Windows & Mac web installers" },
            { label: "Private Slack-channel support" },
            { label: "MSI and PKG installers" },
            { label: "Microsoft and Mac app store" },
            { label: "Download analytics" },
            { label: "Build analytics" },
          ],
        },
        {
          label: "Scale",
          product: products[stage].cli.scale,
          features: [
            {
              label: (
                <>
                  <strong>1,000</strong> builds/month
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>90 day</strong> build retention
                </>
              ),
            },
            {
              label: (
                <>
                  <strong>60,000</strong> downloads/month
                </>
              ),
            },
            { label: "Support for staggered rollouts" },
            { label: "Account manager" },
            { label: "Priority support & onboarding" },
            { label: "Integration assistance" },
          ],
        },
      ]}
      onSelectPrice={onSelectPrice}
      onViewOtherPlans={onViewOtherPlans}
    >
      {children}
    </Plans>
  );
};

interface PlansProps {
  defaultProductId: string;
  filteredProductIds?: string[];
  products: {
    label: string;
    product: Product;
    features: { label: React.ReactNode }[];
  }[];
  featureLimit?: number;
  onSelectPrice: (priceId: string) => Promise<void>;
  onViewOtherPlans?: () => void;
}

interface PlanState {
  loading: boolean;
  filteredProductIds: string[];
  selectedProductId: string;
  period: "monthly" | "yearly";
}

const Plans: React.FC<PlansProps> = ({
  filteredProductIds = [],
  defaultProductId,
  products,
  featureLimit,
  onSelectPrice,
  onViewOtherPlans,
  children,
}) => {
  const [state, setState] = useState<PlanState>({
    selectedProductId: defaultProductId,
    filteredProductIds,
    loading: false,
    period: "yearly",
  });

  const filteredProducts = products.filter(({ product }) => {
    if (!state.filteredProductIds.length) return true;
    return state.filteredProductIds.includes(product.id);
  });

  const displayedProducts = filteredProducts.length
    ? filteredProducts
    : products;

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {children}
      <Flex css={{ gap: "12px", width: "100%" }}>
        {displayedProducts.map(({ label, product, features }, i) => {
          const price = Object.values(product.prices).find(
            (price) =>
              price.status === "active" && price.period === state.period
          );

          const isYearly = state.period === "yearly";
          const amount = isYearly ? price.amount / 12 : price.amount;
          const previousProduct = products[i - 1];

          const limitedFeatures = features.slice(0, featureLimit);
          const tooltipFeatures = features.slice(limitedFeatures.length);

          return (
            <Flex
              key={product.id}
              css={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "16px",
                borderRadius: "4px",
                background: product.id === defaultProductId ? "#F8F8F8" : "",
              }}
            >
              <Flex css={{ flexDirection: "column" }}>
                <Title level={5} style={{ marginBottom: 4 }}>
                  {label}
                </Title>
                <Flex
                  css={{ alignItems: "center", gap: "6px", flexWrap: "wrap" }}
                >
                  <Text>${amount}/month</Text>
                  {isYearly && (
                    <Tag
                      color="green"
                      style={{ border: "0", fontWeight: "bold" }}
                    >
                      -20%
                    </Tag>
                  )}
                </Flex>
                <Flex
                  css={{
                    paddingTop: "8px",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Switch
                    size="small"
                    checked={isYearly}
                    onChange={(checked) => {
                      setState((prev) => ({
                        ...prev,
                        period: checked ? "yearly" : "monthly",
                      }));
                    }}
                  />
                  <Text type="secondary">Annual</Text>
                </Flex>
                <Divider />
                <Flex css={{ flexDirection: "column", gap: "6px" }}>
                  <Text type="secondary" style={{ fontSize: "11px" }}>
                    {previousProduct
                      ? `Everything in ${previousProduct.label}, plus:`
                      : `${label} plan includes:`}
                  </Text>
                  {limitedFeatures.map(({ label }, i) => {
                    return <FeatureCheck key={i}>{label}</FeatureCheck>;
                  })}
                  {tooltipFeatures.length ? (
                    <Popover
                      placement="bottomLeft"
                      content={
                        <Flex css={{ flexDirection: "column", gap: "6px" }}>
                          {tooltipFeatures.map(({ label }, i) => {
                            return <FeatureCheck key={i}>{label}</FeatureCheck>;
                          })}
                        </Flex>
                      }
                    >
                      <Flex
                        css={{
                          alignItems: "baseline",
                          gap: "6px",
                          cursor: "default",
                        }}
                      >
                        <DownOutlined style={{ fontSize: 10 }} />
                        <Text underline style={{ fontSize: 12 }}>
                          {tooltipFeatures.length} more features
                        </Text>
                      </Flex>
                    </Popover>
                  ) : null}
                </Flex>
              </Flex>
              <Flex css={{ flexDirection: "column" }}>
                <Divider />
                <Button
                  type="primary"
                  shape="round"
                  disabled={state.loading}
                  loading={
                    state.loading && state.selectedProductId === product.id
                  }
                  onClick={async () => {
                    setState((prev) => ({
                      ...prev,
                      selectedProductId: product.id,
                      loading: true,
                    }));

                    await onSelectPrice(price.id);
                    setState((prev) => ({ ...prev, loading: false }));
                  }}
                >
                  Start trial
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {displayedProducts.length !== products.length ? (
        <Button
          type="text"
          onClick={() => {
            onViewOtherPlans?.();
            setState((state) => ({ ...state, filteredProductIds: [] }));
          }}
        >
          View other plans
        </Button>
      ) : null}
    </Flex>
  );
};

const FeatureCheck: React.FC = ({ children }) => {
  return (
    <Flex css={{ alignItems: "baseline", gap: "6px" }}>
      <CheckOutlined style={{ fontSize: 10 }} />
      <Text style={{ fontSize: 12 }}>{children}</Text>
    </Flex>
  );
};
